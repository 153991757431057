.logo {
  display: none;
  @include media-breakpoint-up(sm) {
    display: block;
  }
  img {
    height: 50px;
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}
.logo-mobile {
  display: block;
  width: 40px;
  padding: 10px 0 10px 0;
  @include media-breakpoint-up(sm) {
    display: none;
  }
  img {
    width: 100%;
    height: auto;
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}